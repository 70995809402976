import { mapBannersUsp } from '@/core/services/mapBannersUsp';
import { BannersUsp } from '@/core/ui/components/BannersUsp/BannersUsp';
import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';

import { HomepageUspBannersSection } from '../../queries/types';

export const BannersUspSection: FC<{
  section: HomepageUspBannersSection;
}> = ({ section }) => {
  if (!section?.banners) return null;

  const banners = mapBannersUsp(section.banners);

  return (
    <div className={cn('px-5', 'lg:px-6', 'xl:px-0')}>
      <BannersUsp banners={banners} />
    </div>
  );
};
