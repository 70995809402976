import NextLink from 'next/link';

type WithLinkProps = {
  href?: string | null;
  className?: string;
  children: React.ReactElement;
};

export const WithLink = ({ href, className, children }: WithLinkProps) =>
  href ? (
    <NextLink href={href} className={className}>
      {children}
    </NextLink>
  ) : (
    children
  );
