import { CSSProperties } from 'react';

import { cn } from '@/core/ui/utils';
import { WithLink } from '@/modules/layout/components/WithLink';
import { FCC } from '@/types/common';
import { Img } from '@lib/img/components/Img';
import { Button } from '@lib/theme/components/Button';
import { Media } from '@lib/theme/components/Media';

import {
  NATURAL_SLIDE_HEIGHT,
  NATURAL_SLIDE_WIDTH,
} from '../../services/constans';
import { BannersUspSectionSlideProps } from '../../services/types';

const DEFAULT_SRC = `w=${NATURAL_SLIDE_WIDTH}&h=${NATURAL_SLIDE_HEIGHT}&auto=format`;
const DESKTOP_IMG_CONTAINER_WIDTH = 620;
const TABLET_IMG_CONTAINER_WIDTH = 380;
const SLIDE_CUSTOM_STYLES: CSSProperties = {
  height: NATURAL_SLIDE_HEIGHT,
  position: 'relative',
  maxWidth: '100%',
};

export const BannersUspSectionSlideDesktop: FCC<
  BannersUspSectionSlideProps
> = ({
  bannerBackgroundColor,
  desktopImageUrl,
  title,
  ctaLabel,
  subtitle,
  link,
}) => {
  const commonBannerImageProps = {
    width: NATURAL_SLIDE_WIDTH,
    height: NATURAL_SLIDE_HEIGHT,
    alt: 'USP banner slide',
    imageFit: 'cover' as const,
    src: `${desktopImageUrl ?? ''}?${DEFAULT_SRC}`,
    srcSetObj: {
      '2x': `${desktopImageUrl ?? ''}?${DEFAULT_SRC}&dpr=2`,
      '3x': `${desktopImageUrl ?? ''}?${DEFAULT_SRC}&dpr=3`,
    },
  };

  return (
    <WithLink href={link}>
      <div
        className="flex items-center overflow-hidden rounded-xl"
        style={{
          backgroundColor: bannerBackgroundColor,
          height: NATURAL_SLIDE_HEIGHT,
        }}
      >
        <div className="w-full px-12 py-9">
          <h3 className="font-serif text-3xl font-medium italic text-primary-900">
            {title}
          </h3>
          {subtitle && (
            <h4 className="mt-3 text-lg text-primary-900">{subtitle}</h4>
          )}
          {ctaLabel && (
            <div className="mt-4">
              <Button color="tertiary/gray/800">{ctaLabel}</Button>
            </div>
          )}
        </div>

        <div className={cn('flex min-h-[216px] w-full justify-end')}>
          <Media greaterThanOrEqual="lxl">
            <Img
              {...commonBannerImageProps}
              customizeStyle={() => ({
                ...SLIDE_CUSTOM_STYLES,
                width: DESKTOP_IMG_CONTAINER_WIDTH,
              })}
            />
          </Media>

          <Media greaterThanOrEqual="lg" lessThan="lxl">
            <Img
              {...commonBannerImageProps}
              imagePosition="left"
              customizeStyle={() => ({
                ...SLIDE_CUSTOM_STYLES,
                width: TABLET_IMG_CONTAINER_WIDTH,
              })}
            />
          </Media>
        </div>
      </div>
    </WithLink>
  );
};
