import { Category } from '@/modules/categories/queries/types';
import {
  CategoryLandingUspBanners,
  CategoryLandingUspBannersWithoutCategoryAndPromotionBanner,
} from '@/modules/categoryLanding/queries/types';
import {
  HomepageUspBanners,
  HomepageUspBannerWithoutCategoryAndPromotionBanner,
} from '@/modules/home/queries/types';

import { addLinkPropertyToBanner } from './mapBanners';

export const mapBannersUsp = (
  banners: HomepageUspBanners | CategoryLandingUspBanners
) =>
  banners
    .filter(isSupportedUspBanner)
    .filter(({ id }) => id)
    .map(addLinkPropertyToBanner);

function isSupportedUspBanner(
  banner: HomepageUspBanners[number] | Category['webBanners'][number]
): banner is
  | HomepageUspBannerWithoutCategoryAndPromotionBanner
  | CategoryLandingUspBannersWithoutCategoryAndPromotionBanner {
  return (
    banner.__typename !== 'WebCategoryUspBanner' &&
    banner.__typename !== 'WebPromotionUspBanner'
  );
}
